import MyAchievements from '../views/MyAchievements/MyAchievements';
import Details from '../views/MyAchievements/Details';

const myprojectRoutes = [
  {
    path: '/',
    name: 'My Achievements',
    component: MyAchievements
  },
  {
    id: 'achievementDetails',
    path: '/:id/details',
    name: 'Achievement Details',
    component: Details,
    origin: 'achievements'
  },
  {
    redirect: true,
    path: '/achievements',
    pathTo: '/',
    name: 'My Achievements'
  }
];

export default myprojectRoutes;
