import Login from '../views/Auth/Login';
/* import Register from '../views/Auth/Register'; */
import ResetPassword from '../views/Auth/ResetPassword';

const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    short: 'Login',
    mini: 'LP',
    icon: 'users_circle-08',
    component: Login
  } /* 
  {
    path: '/register',
    name: 'Register',
    short: 'Register',
    mini: 'R',
    icon: 'tech_mobile',
    component: Register
  }, */,
  {
    path: '/password-reset/',
    name: 'Reset password',
    short: 'Password',
    mini: 'PP',
    icon: 'objects_key-25',
    component: ResetPassword
  },

  {
    redirect: true,
    from: '*',
    pathTo: '/login'
  }
];

export default authRoutes;
