import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { Button, Spinner } from '../../../components';
import { Pie } from 'react-chartjs-2';
import Switch from 'react-bootstrap-switch';
import Select from 'react-select';
import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import { one, updateOperationStatus } from '../../../helpers/actions/projects';

class OperationsProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      project: {},
      operations: [],
      pieData: {
        labels: [`${props.t('Done')} (0 %)`, `${props.t('To Do')} (100 %)`],
        datasets: [
          {
            backgroundColor: ['#2CA8FF'],
            data: [0, 100]
          }
        ]
      },
      pieces: [],
      piece: null,
      disable: true
    };
  }

  componentWillReceiveProps({ project }) {
    this.getOperationes(project);
  }

  componentWillMount() {
    let { project } = this.props;
    this.getOperationes(project);
  }

  getOperationes(project) {
    this.setState({ loading: true });

    let { pieData, pieces, piece } = this.state,
      { t } = this.props;
    pieData = project.devis
      ? this.updatePieData(project.operations, pieData)
      : {
          labels: [`${t('Done')} (0 %)`, `${t('To Do')} (100 %)`],
          datasets: [
            {
              backgroundColor: ['#2CA8FF'],
              data: [0, 100]
            }
          ]
        };
    pieces = project.devis ? this.getPieceFilter(project.operations) : [];
    piece = !piece ? (pieces.length ? pieces[0] : null) : piece;

    this.setState({
      project,
      operations: project.operations ? cloneDeep(project.operations) : [],
      pieces,
      piece,
      pieData,
      loading: false
    });
  }

  updatePieData(operations, pieData) {
    let { t } = this.props;

    if (operations.length) {
      let part = 0;
      let total = operations.length * 100;
      operations.forEach((operation, key) => {
        part += operation.status;
      });
      part = ((part * 100) / total).toFixed(2);
      pieData.labels = [
        `${t('Done')} (${part} %)`,
        `${t('To Do')} (${100 - part} %)`
      ];
      pieData.datasets[0].data = [part, 100 - part];
    } else {
      pieData.labels = [`${t('Done')} (0 %)`, `${t('To Do')} (100 %)`];
      pieData.datasets[0].data = [0, 100];
    }

    return pieData;
  }

  getSurfaceFilter(operations) {
    let surfaces = [];
    operations.forEach(op => {
      if (!surfaces.includes(op.group)) surfaces.push(op.group);
    });
    return surfaces;
  }

  getPieceFilter(operations) {
    let pieces = [];
    operations.forEach(op => {
      if (!pieces.includes(op.piece)) pieces.push(op.piece);
    });
    return pieces;
  }

  changeFilter(field, value) {
    this.setState({ [field]: value });
  }

  cancelEdition() {
    let { project } = this.state;
    this.setState({
      operations: project.operations ? cloneDeep(project.operations) : [],
      disable: true
    });
  }

  switchChange(id) {
    let { operations } = this.state;
    let index = operations.findIndex(t => t._id === id);

    if (index !== -1) {
      operations[index].status = operations[index].status === 100 ? 0 : 100;
    }

    this.setState({ operations });
  }

  acceptEdition() {
    this.setState({ disable: true, loading: true });

    let { operations, project } = this.state,
      { dispatch } = this.props;

    let promises = [];
    operations.forEach((operation, key) => {
      if (operation.status !== project.operations[key].status)
        promises.push(
          dispatch(updateOperationStatus(project._id, operation._id, operation))
        );
    });

    Promise.all(promises)
      .then(() => this.updateProject(project._id))
      .catch(() => this.setState({ loading: false }));
  }

  updateProject(projectId) {
    let { dispatch } = this.props;

    dispatch(one(projectId))
      .then(project => {
        this.getOperationes(project);
      })
      .catch(() => this.setState({ loading: false }));
  }

  filterOperations() {
    let { operations, piece } = this.state;
    let grouped = filter(operations, { piece: piece });
    grouped = groupBy(grouped, 'group');
    return grouped;
  }

  render() {
    let { loading, project, pieData, pieces, piece, disable } = this.state;

    let grouped = this.filterOperations();

    return (
      <Card className="card-chart card-flex">
        {loading ? <Spinner /> : null}
        <CardHeader>
          <h6>
            <Trans>Operations Progress</Trans>
          </h6>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs={12} md={3}>
              <Pie data={pieData} />
            </Col>
            <Col xs={12} md={9}>
              {project && project.devis ? (
                <div className="card-progress">
                  <Row>
                    <Col xs={12} md={6} className={'pt-3'}>
                      <FormGroup className={'has-label'}>
                        <Label>
                          <Trans>Piece</Trans>
                        </Label>
                        <Select
                          className="primary"
                          clearable={false}
                          options={pieces.map(s => ({
                            label: s,
                            value: s
                          }))}
                          value={piece}
                          onChange={event =>
                            this.changeFilter(
                              'piece',
                              event ? event.value : null
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      {project && project.devis ? (
                        <Button
                          className={'float-right'}
                          color="info"
                          size="sm"
                          onClick={() => this.setState({ disable: false })}
                        >
                          <Trans>Edit</Trans>
                        </Button>
                      ) : null}
                    </Col>
                  </Row>

                  {grouped ? (
                    Object.keys(grouped).map(group => {
                      return (
                        <Row key={group}>
                          <Col xs={12}>
                            <span className="badge badge-info">
                              <h5 className="m-0">{group}</h5>
                            </span>
                          </Col>
                          <Col xs={12}>
                            <ul>
                              {grouped[group].map((operation, key) => {
                                return (
                                  <li key={`${group}-op${key}`}>
                                    <h6>{operation.name}</h6>
                                    <Switch
                                      onText=""
                                      offText=""
                                      onColor={'info'}
                                      offColor={'default'}
                                      disabled={disable}
                                      index={key}
                                      value={operation.status === 100}
                                      onChange={() =>
                                        this.switchChange(operation._id)
                                      }
                                    />
                                  </li>
                                );
                              })}
                            </ul>
                          </Col>
                        </Row>
                      );
                    })
                  ) : (
                    <Row>
                      <Col xs={12} className={'pt-3'}>
                        <Trans>No operations found</Trans>
                      </Col>
                    </Row>
                  )}

                  {disable ? null : (
                    <div className={'text-right mt-3'}>
                      <Button
                        color="default"
                        size="sm"
                        onClick={() => this.cancelEdition()}
                      >
                        <Trans>Cancel</Trans>
                      </Button>
                      <Button
                        color="info"
                        size="sm"
                        onClick={() => this.acceptEdition()}
                      >
                        <Trans>Accept</Trans>
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="card-progress">
                  <p className={'text-not-found text-center'}>
                    <Trans>No Devis Approved</Trans>
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(OperationsProgress));
