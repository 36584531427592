import MyProjects from '../views/MyProjects/MyProjects';
import Details from '../views/MyProjects/Details';
import Orders from '../views/Projects/Order/Orders';
import PlansDetails from '../views/Projects/Plans/PlanDetails';
import DevisEdit from '../views/Projects/Devis/Edit/DevisEdit';
import DevisDetails from '../views/Projects/Devis/Details/DevisDetails';
import DevisPrint from '../views/Projects/Devis/DevisPrint';

const myprojectRoutes = [
  {
    path: '/',
    name: 'My Projects',
    component: MyProjects
  },
  {
    id: 'projectStatus',
    path: '/by-status/:status',
    name: 'My Projects',
    component: MyProjects,
    origin: null
  },
  {
    id: 'projectDetails',
    path: '/:id/details',
    name: 'Project Details',
    component: Details,
    origin: 'projects'
  },
  {
    id: 'projectOrders',
    path: '/:id/orders',
    name: 'Orders',
    component: Orders,
    origin: 'projectDetails'
  },
  {
    id: 'projectNewDevis',
    path: '/:id/devis',
    name: 'Devis',
    component: DevisEdit,
    origin: 'projectDetails'
  },
  {
    id: 'projectEditDevis',
    path: '/:id/devis/:devisId',
    name: 'Devis',
    component: DevisEdit,
    origin: 'projectDetails'
  },
  {
    id: 'projectDetailsDevis',
    path: '/:id/devis/:devisId/details',
    name: 'Devis',
    component: DevisDetails,
    origin: 'projectDetails'
  },
  {
    id: 'projectDevisPrint',
    path: '/:id/devis/:devisId/print',
    name: 'Print Devis',
    component: DevisPrint,
    origin: 'projectEditDevis'
  },
  {
    id: 'projectPlan',
    path: '/:id/plan/:planId',
    name: 'Plan Details',
    component: PlansDetails,
    origin: 'projectDetails'
  },
  {
    redirect: true,
    path: '/my-projects',
    pathTo: '/',
    name: 'Market'
  }
];

export default myprojectRoutes;
