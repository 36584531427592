import Dashboard from '../layouts/Dashboard/Dashboard.jsx';
import Auth from '../layouts/Auth/Auth';

const indexRoutes = [
  { path: '/login', name: 'Login', component: Auth },
  { path: '/register', name: 'Register', component: Auth },
  { path: '/password-reset', name: 'Reset Password', component: Auth },
  { path: '/', name: 'Home', component: Dashboard }
];

export default indexRoutes;
