import React from 'react';
import { Trans, translate } from 'react-i18next';
import {
  Alert,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup
} from 'reactstrap';
import { Button } from '../../components';
import logo from '../../assets/img/ps-logo-white.png';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { auth } from 'firebase';
import { randomIntFromInterval } from '../../helpers/utils';
let imageBack = '';
let random = randomIntFromInterval(1, 4);
import(`../../assets/back/kitchen_${random}.jpg`).then(image => {
  imageBack = image.default;
});

const ResetPassword = translate('translations-fr')(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        loginMessage: null
      };
    }

    onChange(name, nameValid, value, valid) {
      this.setState({
        [name]: value,
        [nameValid]: valid
      });
    }

    validate() {
      let email = this.email;

      this.setState({
        [email.attributes.getNamedItem('namevalid').value]: email.validity.valid
      });

      return email.validity.valid;
    }

    resetPassword() {
      if (this.validate()) {
        let { email } = this.state,
          { history } = this.props;

        auth().languageCode = 'fr';
        auth()
          .sendPasswordResetEmail(email)
          .then(function() {
            history.push('/login');
          })
          .catch(err => this.setState({ loginMessage: err.message }));
      }
    }

    render() {
      let { email, loginMessage } = this.state,
        { t } = this.props;

      return (
        <div>
          <div className="full-page-content">
            <div className="login-page">
              <Container>
                <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                  <Form>
                    <Card className="card-plain">
                      <CardHeader>
                        <div className="logo-container-ps">
                          <img src={logo} alt="now-logo" />
                        </div>
                        {loginMessage ? (
                          <Alert color="danger">
                            <span>{loginMessage}</span>
                          </Alert>
                        ) : null}
                      </CardHeader>
                      <CardBody>
                        <InputGroup
                          size="lg"
                          className={
                            'no-border ' +
                            (this.state.emailFocus ? 'input-group-focus' : '')
                          }
                        >
                          <div className={'input-group-addon'}>
                            <i className="now-ui-icons users_circle-08" />
                          </div>
                          <Input
                            type="email"
                            innerRef={node => (this.email = node)}
                            namevalid="emailValid"
                            placeholder={`${t('Email')}...`}
                            value={email || ''}
                            required="required"
                            onFocus={() => this.setState({ emailFocus: true })}
                            onBlur={() => this.setState({ emailFocus: false })}
                            onChange={event =>
                              this.onChange(
                                'email',
                                'emailValid',
                                event.target.value,
                                event.target.validity.valid
                              )
                            }
                            className="login_ipunt"
                          />
                        </InputGroup>
                        {this.state.emailValid === false ? (
                          <p className="text-center text-danger">
                            {t('Invalid Email')}
                          </p>
                        ) : null}
                      </CardBody>
                      <CardFooter>
                        <Button
                          color="primary"
                          size="lg"
                          block
                          round
                          onClick={() => this.resetPassword()}
                        >
                          <Trans>Reset Password</Trans>
                        </Button>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Container>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: 'url(' + imageBack + ')' }}
          />
        </div>
      );
    }
  }
);

const mapStateToProps = state => {
  return { auth: state.fb.auth };
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(ResetPassword);
