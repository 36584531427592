import PricingPage from '../views/Templates/Pages/PricingPage.jsx';
import LoginPage from '../views/Templates/Pages/LoginPage.jsx';
import RegisterPage from '../views/Templates/Pages/RegisterPage.jsx';
import LockScreenPage from '../views/Templates/Pages/LockScreenPage.jsx';

const pagesRoutes = [
  {
    path: '/pages/register-page',
    name: 'Register Page',
    short: 'Register',
    mini: 'RP',
    icon: 'tech_mobile',
    component: RegisterPage
  },
  {
    path: '/pages/login',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    icon: 'users_circle-08',
    component: LoginPage
  },
  {
    path: '/pages/pricing-page',
    name: 'Pricing Page',
    short: 'Pricing',
    mini: 'PP',
    icon: 'business_money-coins',
    component: PricingPage
  },
  {
    path: '/pages/lock-screen-page',
    name: 'Lock Screen Page',
    short: 'Lock',
    mini: 'LSP',
    icon: 'ui-1_lock-circle-open',
    component: LockScreenPage
  },
  {
    redirect: true,
    path: '/pages',
    pathTo: '/pages/register-page',
    name: 'Register Page'
  }
];

export default pagesRoutes;
