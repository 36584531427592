import React from 'react';
import Loadable from 'react-loadable';
import Spinner from '../components/Spinner/Spinner';

const Updates = Loadable({
  loader: () => import('../views/Updates/Updates'),
  loading: () => <Spinner />
});

const Market = Loadable({
  loader: () => import('../layouts/Market/Market'),
  loading: () => <Spinner />
});

const Supervisors = Loadable({
  loader: () => import('../views/Users/Supervisors/Supervisors'),
  loading: () => <Spinner />
});

const Clients = Loadable({
  loader: () => import('../views/Users/Clients/Clients'),
  loading: () => <Spinner />
});

const MyProjects = Loadable({
  loader: () => import('../layouts/MyProjects/MyProjects'),
  loading: () => <Spinner />
});

const Calendar = Loadable({
  loader: () => import('../views/Calendar/Calendar.jsx'),
  loading: () => <Spinner />
});

const Documents = Loadable({
  loader: () => import('../views/Users/Documents/Documents.jsx'),
  loading: () => <Spinner />
});

const MyAchievements = Loadable({
  loader: () => import('../layouts/MyAchievements/MyAchievements'),
  loading: () => <Spinner />
});

const Partners = Loadable({
  loader: () => import('../views/Partners/Partners'),
  loading: () => <Spinner />
});

const Finances = Loadable({
  loader: () => import('../views/Stats/Finances'),
  loading: () => <Spinner />
});

const Dashboard = Loadable({
  loader: () => import('../views/Dashboard/Dashboard'),
  loading: () => <Spinner />
});

let dashRoutes = [
  {
    path: '#dashboard',
    name: 'Dashboard',
    mini: 'D',
    state: 'dashboard',
    collapse: true,
    icon: 'design_app',
    views: [
      {
        path: '/dashboard',
        name: 'Suivi',
        mini: <i className={'fa fa-cubes'} />,
        icon: 'design_app',
        component: Dashboard
      },
      {
        path: '/finances',
        name: 'Finances',
        mini: <i className={'fa fa-money'} />,
        icon: 'design_app',
        component: Finances
      }
    ]
  },
  {
    id: 'updates',
    path: '/updates',
    name: 'Notifications',
    icon: 'ui-1_bell-53',
    component: Updates,
    origin: null
  },
  {
    id: 'market',
    path: '/market',
    name: 'Market',
    icon: 'shopping_shop',
    component: Market,
    origin: null
  },
  {
    path: '#users',
    name: 'Users',
    mini: 'U',
    state: 'users',
    collapse: true,
    icon: 'users_circle-08',
    views: [
      {
        path: '/users/supervisors',
        name: 'Supervisors',
        mini: 'S',
        icon: 'users_circle-08',
        component: Supervisors
      },
      {
        path: '/users/clients',
        name: 'Clients',
        mini: 'C',
        icon: 'users_circle-08',
        component: Clients
      }
    ]
  },
  {
    id: 'projects',
    path: '/my-projects',
    name: 'My Projects',
    icon: 'design-2_ruler-pencil',
    component: MyProjects,
    origin: null
  },
  /*
  {
    id: 'requests',
    path: '/project-requests',
    name: 'My Requests',
    icon: 'shopping_tag-content',
    component: MyRequests,
    origin: null
  },*/
  {
    id: 'calendar',
    path: '/calendar',
    name: 'Calendar',
    icon: 'ui-1_calendar-60',
    component: Calendar,
    origin: null
  },
  {
    id: 'documents',
    path: '/documents',
    name: 'Documents',
    icon: 'files_single-copy-04',
    component: Documents,
    origin: null
  },
  {
    id: 'achievements',
    path: '/achievements',
    name: 'My Achievements',
    icon: 'business_briefcase-24',
    component: MyAchievements,
    origin: null
  },
  {
    id: 'partners',
    path: '/partners',
    name: 'Partners',
    icon: 'users_single-02',
    component: Partners,
    origin: null
  },
  {
    path: '*',
    component: Dashboard
  }
];
export default dashRoutes;
