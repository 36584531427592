import Market from '../views/Market/Market';
import Postulate from '../views/Market/Postulate';
import DevisDetails from '../views/Projects/Devis/Details/DevisDetails';

const marketRoutes = [
  {
    path: '/',
    name: 'Market',
    component: Market
  },
  {
    id: 'postulate',
    path: '/:id/postulate',
    name: 'Postulate',
    component: Postulate,
    origin: 'market'
  },
  {
    id: 'marketDetailsDevis',
    path: '/:id/devis/:devisId/details',
    name: 'Devis',
    component: DevisDetails,
    origin: 'postulate'
  },
  {
    redirect: true,
    path: '/market',
    pathTo: '/',
    name: 'Market'
  }
];

export default marketRoutes;
